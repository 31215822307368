import React from 'react';
import { connect } from 'react-redux';

import {shouldInlineMap} from './helpers/layout';
import Map from './Map';
import Overlay from './Overlay';
import Sidepanel from './Sidepanel';

const mapStateToProps = (state, ownProps) => ({
  isSidePanelOpen: state.isSidePanelOpen
});
const mapDispatchToProps = {};
class App extends React.Component {
  render() {
    const hideMap = shouldInlineMap() && this.props.isSidePanelOpen;
    return (
      <div>
        <Overlay />
        {!hideMap && <Map />}
        <Sidepanel />
      </div>
    );
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
