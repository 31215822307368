import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import Scrollbar from 'react-scrollbars-custom';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import history from './history';
import * as socialHelper from './helpers/social';
import { getYearString, yearInRange } from './helpers/date';
import { parseURL, getLoadingStatus, getEntityFromUrl } from './helpers/links';

import List from './content_components/List';
import Year from './content_components/Year';
import Ruler from './content_components/Ruler';
import State from './content_components/State';
import Place from './content_components/Place';
import Event from './content_components/Event';
import Scholar from './content_components/Scholar';
import Monument from './content_components/Monument';

import Help from './static_components/Help';
import Settings from './static_components/Settings';
import About from './static_components/About';
import States from './static_components/States';
import Cities from './static_components/Cities';
import Search from './static_components/Search';


import {
  loadInitialData,
  gotoDate,
  loadPage,
  toggleSidePanel,
} from './store/actions';
import AlfehrestDate from './helpers/AlFehrestDate';
import { isOnServer } from './helpers/env';

function throttled(delay, fn) {
  let lastCall = 0;
  return function(...args) {
    const now = new Date().getTime();
    if (now - lastCall < delay) {
      return;
    }
    lastCall = now;
    return fn(...args);
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    currentDate: new AlfehrestDate(Number(ownProps.match.params.date)),
    pageCache: state.pageCache,
    dataLookup: state.dataLookup,
    currentPage: state.currentPage,
    lastRequestedPage: state.lastRequestedPage,
    lastRequestFailed: state.lastRequestFailed,
    linkList: state.linkList,
    initialDataStatus: state.initialDataStatus,
    isSidePanelOpen: state.isSidePanelOpen,
    settings: state.settings
  };
};

// Maps component props to action creators
const mapDispatchToProps = {
  gotoDate: gotoDate,
  loadPage: loadPage,
  toggleSidePanel: toggleSidePanel,
  loadInitialData: loadInitialData,
};

const propTypes = {
  currentPage: PropTypes.string,
  currentDate: PropTypes.instanceOf(AlfehrestDate),
  isFetching: PropTypes.bool,
  isSidePanelOpen: PropTypes.bool,
  gotoDate: PropTypes.func,
  toggleSidePanel: PropTypes.func
};
const defaultProps = {};

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onDateClicked = this.onDateClicked.bind(this);
    this.onDateChanged = throttled(50, this.onDateChanged.bind(this));
    this.onHelpClicked = this.onHelpClicked.bind(this);
    this.onAllStatesClicked = this.onAllStatesClicked.bind(this);
    this.onAllCitiesClicked = this.onAllCitiesClicked.bind(this);
    this.onCloseClicked = this.onCloseClicked.bind(this);
    this.onAboutClicked = this.onAboutClicked.bind(this);
    this.onScrollStart = this.onScrollStart.bind(this);
    this.onScrollStop = this.onScrollStop.bind(this);
    this.onSearchClicked = this.onSearchClicked.bind(this);
    this.onSettingsClicked = this.onSettingsClicked.bind(this);
    this.scrollbar = null;
    this.hideTimeout = null;
  }

  onScrollStart() {
    if (this.hideTimeout) {
      clearTimeout(this.hideTimeout);
      this.hideTimeout = undefined;
    }
    if(this.scrollbar.trackVertical) {
      this.scrollbar.trackVertical.style.opacity = 1;
    }
  }

  onScrollStop() {
    if (this.hideTimeout) {
      clearTimeout(this.hideTimeout);
    }
    this.hideTimeout = setTimeout(() => {
      if(this.scrollbar.trackVertical) {
        this.scrollbar.trackVertical.style.opacity = 0;
      }
    }, 500);
  }

  onDateClicked(ev) {
    this.props.gotoDate(this.props.currentDate, true);
    this.props.toggleSidePanel(true);
  }

  onDateChanged(value) {
    const parts = history.location.pathname.split('/');
    parts[1] = value;
    history.push(parts.join('/'));
  }

  getSidepanelClassName() {
    const numeralClassname = this.props.settings.arabic_numerals ? 'arabic' : 'hindi';
    if (this.props.initialDataStatus !== 'done') {
      return `invisible ${numeralClassname}`;
    }
    return this.props.isSidePanelOpen ? `${numeralClassname} is-slid` : numeralClassname;
  }

  isLoading() {
    return (
      getLoadingStatus(this.props.currentPage, this.props.pageCache) !== 'done'
    );
  }

  getInfopanelClassName() {
    return 'info-container';
  }

  onSearchClicked() {
    history.push(`${this.props.match.url}/search`);
    this.props.toggleSidePanel(true);
  }

  onSettingsClicked() {
    history.push(`${this.props.match.url}/settings`);
    this.props.toggleSidePanel(true);
  }

  onAboutClicked() {
    history.push(`${this.props.match.url}/about`);
    this.props.toggleSidePanel(true);
  }

  onAllStatesClicked() {
    history.push(`${this.props.match.url}/all_states`);
    this.props.toggleSidePanel(true);
  }

  onAllCitiesClicked() {
    history.push(`${this.props.match.url}/all_cities`);
    this.props.toggleSidePanel(true);
  }

  onHelpClicked() {
    history.push(`${this.props.match.url}/help`);
    this.props.toggleSidePanel(true);
  }

  onCloseClicked() {
    this.props.toggleSidePanel(false);
  }

  getContent() {
    const date = new AlfehrestDate(Number(this.props.match.params.date));
    const match = this.props.match;
    console.log(date);
    if (this.props.initialDataStatus === 'done') {
      return (
        <Switch>
          <Route
            path={`${match.url}/list/:data`}
            render={(props) => (
              <List data={props.match.params.data} date={date} />
            )}
          />
          <Route
            path={`${match.url}/states/:id`}
            render={(props) => (
              <State id={parseInt(props.match.params.id, 10)} date={date} />
            )}
          />
          <Route
            path={`${match.url}/monuments/:id`}
            render={(props) => (
              <Monument id={parseInt(props.match.params.id, 10)} date={date} />
            )}
          />
          <Route
            path={`${match.url}/places/:id`}
            render={(props) => (
              <Place id={parseInt(props.match.params.id, 10)} date={date} />
            )}
          />
          <Route
            path={`${match.url}/rulers/:id`}
            render={(props) => (
              <Ruler id={parseInt(props.match.params.id, 10)} date={date} />
            )}
          />
          <Route
            path={`${match.url}/scholars/:id`}
            render={(props) => (
              <Scholar id={parseInt(props.match.params.id, 10)} date={date} />
            )}
          />
          <Route
            path={`${match.url}/events/:id`}
            render={(props) => (
              <Event id={parseInt(props.match.params.id, 10)} date={date} />
            )}
          />
          <Route
            path={`${match.url}/search`}
            render={(props) => (
              <Search date={date} />
            )}
          />
          <Route exact path={`${match.url}/about`} component={About} />
          <Route exact path={`${match.url}/settings`} component={Settings} />
          <Route
            exact
            path={`${match.url}/all_states`}
            render={() => <States date={date} />}
          />
          <Route
            exact
            path={`${match.url}/all_cities`}
            render={() => <Cities date={date} />}
          />
          <Route render={(props) => <Year date={date} match={props.match} />} />
        </Switch>
      );
    }
  }

  renderContent() {
    if(isOnServer()) {
      return <div className={this.getInfopanelClassName()}>
        {this.getContent()}
      </div>
    }
    return <Scrollbar
        ref={(ref) => {this.scrollbar = ref;}}
        onScrollStart={this.onScrollStart}
        onScrollStop={this.onScrollStop}
        scrollX={false}
        style={{ width: '100%', height: '100%' }}
      >
        <div className={this.getInfopanelClassName()}>
          {this.getContent()}
        </div>
      </Scrollbar>
  }

  render() {
    const marks = {};

    for(let i=700; i<=1500; i += 50) {
      const j = new AlfehrestDate(i, 1, 1, 'j');
      const h = j.toHijri();
      marks[j.toJD()] = `${j.toStringFormat("%Y%c")} (${h.toStringFormat("%Y%c")})`
    }

    return (
      <div id="side-panel" className={this.getSidepanelClassName()} >
        <div id="menu-panel">
          <h1 className="logo" />
          <ul className="side-menu">
            <li className="close" onClick={this.onCloseClicked} />
            <li className="about" onClick={this.onAboutClicked}>
              عن الفهرست
            </li>
            <li className="search" onClick={this.onSearchClicked}>
              بحث
            </li>
            <li className="settings" onClick={this.onSettingsClicked}>
              إعدادات
            </li>
          </ul>
          <div className="social-media-pane">
            <a
              className="share facebook"
              href={socialHelper.getFacebookLink()}
              target="_blank"
            />
            <a
              className="share twitter"
              href={socialHelper.getTwitterLink()}
              target="_blank"
            />
            <a
              className="share reddit"
              href={socialHelper.getRedditLink()}
              target="_blank"
            />
            <br clear="all" />
          </div>
        </div>
        <div id="timeline-panel">
          <div id="year-panel" onClick={this.onYearClicked}>
            <span>{this.props.currentDate.toStringFormat("%B %Y%c")}</span><br />
            <span>{this.props.currentDate.toHijri().toStringFormat("%B %Y%c")}</span><br />
          </div>
          <div id="slider-panel">
            <Slider
              vertical
              min={new AlfehrestDate(700, 1, 1, 'j').toJD()}
              max={new AlfehrestDate(1500, 12, 31, 'j').toJD()}
              marks={marks}
              included={false}
              defaultValue={new AlfehrestDate(1000, 1, 1, 'j').toJD()}
              value={this.props.currentDate.toJD()}
              onChange={this.onDateChanged}
            />
          </div>
        </div>
        <div id="info-panel">{this.renderContent()}</div>
      </div>
    );
  }
}

Sidebar.defaultProps = defaultProps;
Sidebar.propTypes = propTypes;

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Sidebar)
);
