import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  publications: PropTypes.arrayOf(PropTypes.object).isRequired
};
const defaultProps = {};

class Publications extends React.Component {

  renderItems(items) {
    const output = [];
    let counter = 0;
    items.forEach((item) => {
      let name = item.name;
      let url = item.ideo_id ? `https://ideo.diamondrda.org/work/${item.ideo_id}?lang=ar` : null;
      let html = <span>{name}</span>;
      if(url) {
        html = <a target="_blank" rel="noopener noreferrer" href={url}>{name}</a>;
      }
      counter += 1;
      output.push(
        <li key={`ref-${counter}`}>
          {html}
        </li>
      );
    });
    return output;
  }

  render() {
   if(this.props.publications.length === 0) {
      return null;
    }
    return (
      <div className="references">
        <h4>كتبه</h4>
        <ol>
          {this.renderItems(this.props.publications)}
        </ol>
      </div>
    );
  }
}

Publications.defaultProps = defaultProps;
Publications.propTypes = propTypes;

export default Publications;
